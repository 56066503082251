import * as dat from "../node_modules/lil-gui";
import * as THREE from "../node_modules/three";
import {
	OrbitControls
} from "../node_modules/three/examples/jsm/controls/OrbitControls.js";
import {
	GLTFLoader
} from "../node_modules/three/examples/jsm/loaders/GLTFLoader.js";
import {
	DRACOLoader
} from "../node_modules/three/examples/jsm/loaders/DRACOLoader.js";
import {
	CSS3DRenderer,
	CSS3DObject,
} from "../node_modules/three/examples/jsm/renderers/CSS3DRenderer.js";
const menuBtn = document.getElementById("menu-toggle");
const menuItself = document.getElementById("menu-itself");
let iframeDiv, iframe;
const cursor = document.querySelector(".model-cursor");


const renderModel = (navigationInstruction) => {

	/**
	 * Base
	 */
	// Debug
	// const gui = new dat.GUI({
	//   width: 300,
	// });


	// PARAMS
	const parameters = {};
	parameters.modelmoveY = 0; // (-1.75)
	parameters.modelmoveX = -1;
	parameters.ambientLightColor = "#564355";
	parameters.logoMaterialColor = "#564355";
	parameters.directionalLightColor = "#bdbdbd";
	parameters.candleLightColor = 0xffaa33;
	// Canvas
	const canvas = document.querySelector("canvas.model-webgl");
	// Scene
	const scene = new THREE.Scene();
	const cssscene = new THREE.Scene();


	// LOADING
	const tl = gsap.timeline();
	const loadingBg = document.querySelector('.loading-background')
	const loadingManager = new THREE.LoadingManager(
		// Loaded
		() =>
		{
				tl.to(loadingBg, {
					alpha: "0",
					duration: 0.5
				})

			gsap.delayedCall(2, () => {
					loadingBg.classList.add('dead')
			})
		},

		// Progress
		(itemUrl, itemsLoaded, itemsTotal) =>
		{
			loadingBg.classList.remove('dead')
			const progressRatio = itemsLoaded / itemsTotal
		}
	)
	
	const material = new THREE.MeshBasicMaterial({
		color: 0x3e3348
	});
	const smasherGeometry = new THREE.BoxGeometry(1.5, 1.5, 1.5);
	const smasher = new THREE.Mesh(smasherGeometry, material);
	scene.add(smasher);
	smasher.position.x = 3.95
	smasher.position.y = 3
	smasher.position.z = -0.35
	smasher.scale.y = 0
	smasher.scale.x = 0.8
	smasher.scale.z = 0.5
	// ROTATION HACK
	function rotateObject(object, degreeX = 0, degreeY = 0, degreeZ = 0) {
		object.rotateX(THREE.Math.degToRad(degreeX));
		object.rotateY(THREE.Math.degToRad(degreeY));
		object.rotateZ(THREE.Math.degToRad(degreeZ));
	}
	// OBJECT GROUPS
	let raycasterObjects = [];
	const global = new THREE.Group();
	scene.add(global);
	const tape = new THREE.Group();
	scene.add(tape);
	/**
	 * Loaders
	 */
	// Texture loader
	const textureLoader = new THREE.TextureLoader();
	// Draco loader
	const dracoLoader = new DRACOLoader();
	dracoLoader.setDecoderPath("static/draco/");
	// GLTF loader
	const gltfLoader = new GLTFLoader(loadingManager);
	gltfLoader.setDRACOLoader(dracoLoader);
	var firstReel, secondReel, theWalls;
	scene.add(firstReel, secondReel, theWalls);
	//scene
	gltfLoader.load("static/backgroundfinal.glb", function(background) {
		background.scene.traverse(function(child) {
			if (child.isMesh) {
				child.castShadow = true;
				child.receiveShadow = true;
				// child.geometry.computeVertexNormals()
			}
		});
		scene.add(background.scene);
		global.add(background.scene);
		// raycasterObjects.push(background.scene)
	});
	const logoMaterial = new THREE.MeshBasicMaterial({
		color: "#d1936a",
	});
	//BlinkLogo
	gltfLoader.load("static/newLogoFlat.glb", function(flatlogo) {
		flatlogo.scene.traverse(function(child) {
			if (child.isMesh) {
				child.material = logoMaterial;
				// child.receiveShadow = true;
				// child.geometry.computeVertexNormals()
			}
		});
		scene.add(flatlogo.scene);
		global.add(flatlogo.scene);
		// raycasterObjects.push(background.scene)
	});
	//BOX1
	gltfLoader.load("static/box.glb", function(box1) {
		box1.scene.traverse(function(child) {
			if (child.isMesh) {
				child.receiveShadow = true;
				child.castShadow = true;
			}
		});
		box1.scene.position.x = 11.6;
		box1.scene.position.y = 1.79
		box1.scene.position.z = -5
		var box = box1.scene
		const tl4 = gsap.timeline({
			repeat: -1,
			defaults: {
				ease: Linear.easeNone
			},
		});
		tl4.to(box.position, {
			duration: 0,
			x: 11.6
		});
		tl4.to(box.position, {
			duration: 0,
			y: 1.79
		});
		tl4.to(box.position, {
			duration: 0,
			z: -5
		});
		tl4.to(box.position, {
			duration: 2,
			x: 4.9
		}); //2
		tl4.to(box.position, {
			duration: 2,
			z: -0.25
		}); //4
		tl4.to(smasher.position, {
			duration: 0.05,
			y: 1.5
		}, "-=0.1");
		tl4.to(box.scale, {
			duration: 0.05,
			y: 0
		}, "-=0.1");
		tl4.to(smasher.scale, {
			duration: 0.05,
			y: 0.4
		}, "-=0.1");
		tl4.to(box.scale, {
			duration: 0,
			x: 0
		});
		tl4.to(box.scale, {
			duration: 0,
			z: 0
		});
		tl4.to(smasher.position, {
			duration: 0.05,
			y: 1.5
		}, "+=1"); //5.05
		tl4.to(smasher.position, {
			duration: 0.2,
			y: 3
		}); // 5.25
		tl4.to(smasher.scale, {
			duration: 0.2,
			y: 0
		}, "-=0.2");
		scene.add(box1.scene);
		global.add(box1.scene);
	});
	//BOX2
	gltfLoader.load("static/box.glb", function(box2) {
		box2.scene.traverse(function(child) {
			if (child.isMesh) {
				child.receiveShadow = true;
				child.castShadow = true;
			}
		});
		box2.scene.position.x = 11.6;
		box2.scene.position.y = 1.79
		box2.scene.position.z = -5
		var box = box2.scene
		const tl4 = gsap.timeline({
			repeat: -1,
			delay: 1.75,
			defaults: {
				ease: Linear.easeNone
			},
		});
		tl4.to(box.position, {
			duration: 0,
			x: 11.6
		});
		tl4.to(box.position, {
			duration: 0,
			y: 1.79
		});
		tl4.to(box.position, {
			duration: 0,
			z: -5
		});
		tl4.to(box.position, {
			duration: 2,
			x: 4.9
		}); //2
		tl4.to(box.position, {
			duration: 2,
			z: -0.25
		}); //4
		tl4.to(smasher.position, {
			duration: 0.05,
			y: 1.5
		}, "-=0.1");
		tl4.to(box.scale, {
			duration: 0.05,
			y: 0
		}, "-=0.1");
		tl4.to(smasher.scale, {
			duration: 0.05,
			y: 0.4
		}, "-=0.1");
		tl4.to(box.scale, {
			duration: 0,
			x: 0
		});
		tl4.to(box.scale, {
			duration: 0,
			z: 0
		});
		tl4.to(smasher.position, {
			duration: 0.05,
			y: 1.5
		}, "+=1"); //5.05
		tl4.to(smasher.position, {
			duration: 0.2,
			y: 3
		}); // 5.25
		tl4.to(smasher.scale, {
			duration: 0.2,
			y: 0
		}, "-=0.2");
		scene.add(box2.scene);
		global.add(box2.scene);
	});
	//BOX3
	gltfLoader.load("static/box.glb", function(box3) {
		box3.scene.traverse(function(child) {
			if (child.isMesh) {
				child.receiveShadow = true;
				child.castShadow = true;
			}
		});
		box3.scene.position.x = 11.6;
		box3.scene.position.y = 1.79
		box3.scene.position.z = -5
		var box = box3.scene
		const tl4 = gsap.timeline({
			repeat: -1,
			delay: 3.5,
			defaults: {
				ease: Linear.easeNone
			},
		});
		tl4.to(box.position, {
			duration: 0,
			x: 11.6
		});
		tl4.to(box.position, {
			duration: 0,
			y: 1.79
		});
		tl4.to(box.position, {
			duration: 0,
			z: -5
		});
		tl4.to(box.position, {
			duration: 2,
			x: 4.9
		}); //2
		tl4.to(box.position, {
			duration: 2,
			z: -0.25
		}); //4
		tl4.to(smasher.position, {
			duration: 0.05,
			y: 1.5
		}, "-=0.1");
		tl4.to(box.scale, {
			duration: 0.05,
			y: 0
		}, "-=0.1");
		tl4.to(smasher.scale, {
			duration: 0.05,
			y: 0.4
		}, "-=0.1");
		tl4.to(box.scale, {
			duration: 0,
			x: 0
		});
		tl4.to(box.scale, {
			duration: 0,
			z: 0
		});
		tl4.to(smasher.position, {
			duration: 0.05,
			y: 1.5
		}, "+=1"); //5.05
		tl4.to(smasher.position, {
			duration: 0.2,
			y: 3
		}); // 5.25
		tl4.to(smasher.scale, {
			duration: 0.2,
			y: 0
		}, "-=0.2");
		scene.add(box3.scene);
		global.add(box3.scene);
	});
	//BOX4
	gltfLoader.load("static/box.glb", function(box4) {
		box4.scene.traverse(function(child) {
			if (child.isMesh) {
				child.receiveShadow = true;
				child.castShadow = true;
			}
		});
		box4.scene.position.x = -4.425;
		box4.scene.position.y = 1.79
		box4.scene.position.z = -3.1
		var box = box4.scene
		const tl4 = gsap.timeline({
			repeat: -1,
			// delay: 3.5,
			defaults: {
				ease: Linear.easeNone
			},
		});
		tl4.to(box.position, {
			duration: 3.5,
			z: -12
		});
		tl4.to(box.position, {
			duration: 0,
			z: -3.1
		});
		scene.add(box4.scene);
		global.add(box4.scene);
	});
	//VHS
	gltfLoader.load("static/vhsblue.glb", function(vhs1) {
		vhs1.scene.traverse(function(child) {});
		vhs1.scene.position.y = -0.2
		var box = vhs1.scene
		const tl4 = gsap.timeline({
			repeat: -1,
			delay: 5,
			defaults: {
				ease: Linear.easeNone
			},
		});
		tl4.to(box.position, {
			duration: 0.1,
			y: -0.2
		}); // (NEW 6.85)
		tl4.to(box.position, {
			duration: 0,
			y: 0
		});
		tl4.to(box.position, {
			duration: 1,
			z: 1.9
		}); //6 (NEW 7.85)
		tl4.to(box.position, {
			duration: 2.5,
			x: 4.75
		}); // 8.5 (10.35)
		tl4.to(box.position, {
			duration: 0.05,
			y: 0.1
		}); // 8.55 (10.45)
		tl4.to(box.position, {
			duration: 0.05,
			y: 0.0
		}); // 8.6 (10.50)
		tl4.to(box.position, {
			duration: 0.05,
			y: 0.1
		}); //8.65 (10.55)
		tl4.to(box.position, {
			duration: 0.05,
			y: 0.0
		}); //8.7 (10.56)
		tl4.to(box.position, {
			duration: 0.05,
			y: 0.1
		}); //8.75 (10.65)
		tl4.to(box.position, {
			duration: 0.05,
			y: 0.0
		}); //8.8 (10.7)
		tl4.to(box.position, {
			duration: 0.2,
			y: 2.5
		}); //9 (NEW 10.75)
		tl4.to(box.position, {
			duration: 1.15,
			y: 2.5
		}); //10.25
		scene.add(vhs1.scene);
		global.add(vhs1.scene);
	});
	//VHS2
	gltfLoader.load("static/vhsgreen.glb", function(vhs3) {
		vhs3.scene.traverse(function(child) {});
		vhs3.scene.position.y = -0.2
		var box = vhs3.scene
		const tl4 = gsap.timeline({
			repeat: -1,
			delay: 6.65,
			defaults: {
				ease: Linear.easeNone
			},
		});
		tl4.to(box.position, {
			duration: 0.1,
			y: -0.2
		}); // (NEW 6.85)
		tl4.to(box.position, {
			duration: 0,
			y: 0
		});
		tl4.to(box.position, {
			duration: 1,
			z: 1.9
		}); //6 (NEW 7.85)
		tl4.to(box.position, {
			duration: 2.5,
			x: 4.75
		}); // 8.5 (10.35)
		tl4.to(box.position, {
			duration: 0.05,
			y: 0.1
		}); // 8.55 (10.45)
		tl4.to(box.position, {
			duration: 0.05,
			y: 0.0
		}); // 8.6 (10.50)
		tl4.to(box.position, {
			duration: 0.05,
			y: 0.1
		}); //8.65 (10.55)
		tl4.to(box.position, {
			duration: 0.05,
			y: 0.0
		}); //8.7 (10.56)
		tl4.to(box.position, {
			duration: 0.05,
			y: 0.1
		}); //8.75 (10.65)
		tl4.to(box.position, {
			duration: 0.05,
			y: 0.0
		}); //8.8 (10.7)
		tl4.to(box.position, {
			duration: 0.2,
			y: 2.5
		}); //9 (NEW 10.75)
		tl4.to(box.position, {
			duration: 1.15,
			y: 2.5
		}); //10.25
		scene.add(vhs3.scene);
		global.add(vhs3.scene);
	});
	//VHS3
	gltfLoader.load("static/vhsbrown.glb", function(vhs4) {
		vhs4.scene.traverse(function(child) {});
		vhs4.scene.position.y = -0.2
		var box = vhs4.scene
		const tl4 = gsap.timeline({
			repeat: -1,
			delay: 8.4,
			defaults: {
				ease: Linear.easeNone
			},
		});
		tl4.to(box.position, {
			duration: 0.1,
			y: -0.2
		}); // (NEW 6.85)
		tl4.to(box.position, {
			duration: 0,
			y: 0
		});
		tl4.to(box.position, {
			duration: 1,
			z: 1.9
		}); //6 (NEW 7.85)
		tl4.to(box.position, {
			duration: 2.5,
			x: 4.75
		}); // 8.5 (10.35)
		tl4.to(box.position, {
			duration: 0.05,
			y: 0.1
		}); // 8.55 (10.45)
		tl4.to(box.position, {
			duration: 0.05,
			y: 0.0
		}); // 8.6 (10.50)
		tl4.to(box.position, {
			duration: 0.05,
			y: 0.1
		}); //8.65 (10.55)
		tl4.to(box.position, {
			duration: 0.05,
			y: 0.0
		}); //8.7 (10.56)
		tl4.to(box.position, {
			duration: 0.05,
			y: 0.1
		}); //8.75 (10.65)
		tl4.to(box.position, {
			duration: 0.05,
			y: 0.0
		}); //8.8 (10.7)
		tl4.to(box.position, {
			duration: 0.2,
			y: 2.5
		}); //9 (NEW 10.75)
		tl4.to(box.position, {
			duration: 1.15,
			y: 2.5
		}); //10.25
		scene.add(vhs4.scene);
		global.add(vhs4.scene);
	});
	// WALLS
	const wallsMaterial = new THREE.MeshBasicMaterial({
		color: "#ffffff",
		transparent: true,
		opacity: 1,
	});
	gltfLoader.load("static/walls8.glb", function(walls) {
		theWalls = walls.scene;
		walls.scene.traverse(function(child) {
			if (child.isMesh) {
				child.material = wallsMaterial;
				// child.receiveShadow = true;
				// child.geometry.computeVertexNormals()
			}
		});
		scene.add(walls.scene);
		global.add(walls.scene);
	});
	gltfLoader.load("static/abyss.glb", function(abyss) {
		abyss.scene.traverse(function(child) {
			if (child.isMesh) {
				child.castShadow = true;
				child.receiveShadow = true;
				// child.geometry.computeVertexNormals()
			}
		});
		scene.add(abyss.scene);
		global.add(abyss.scene);
	});
	// IN DEVELOPMENT
	gltfLoader.load("static/indev.glb", function(indev) {
		indev.scene.traverse(function(child) {
			if (child.isMesh) {
				child.castShadow = true;
				child.receiveShadow = true;
				// child.geometry.computeVertexNormals()
			}
		});
		scene.add(indev.scene);
		global.add(indev.scene);
		raycasterObjects.push(indev.scene);
	});
	// IN PRODUCTION
	gltfLoader.load("static/inprod.glb", function(inprod) {
		inprod.scene.traverse(function(child) {
			if (child.isMesh) {
				child.castShadow = true;
				child.receiveShadow = true;
				// child.geometry.computeVertexNormals()
			}
		});
		scene.add(inprod.scene);
		global.add(inprod.scene);
		raycasterObjects.push(inprod.scene);
	});
	// JOBS
	gltfLoader.load("static/jobsNew.glb", function(jobs) {
		jobs.scene.traverse(function(child) {
			if (child.isMesh) {
				child.castShadow = true;
				child.receiveShadow = true;
				// child.geometry.computeVertexNormals()
			}
		});
		scene.add(jobs.scene);
		global.add(jobs.scene);
		raycasterObjects.push(jobs.scene);
	});
	// CONTACT
	gltfLoader.load("static/contactNew.glb", function(contact) {
		contact.scene.traverse(function(child) {
			if (child.isMesh) {
				child.castShadow = true;
				child.receiveShadow = true;
				// child.geometry.computeVertexNormals()
				// console.log(child.material)
			}
		});
		scene.add(contact.scene);
		global.add(contact.scene);
		raycasterObjects.push(contact.scene);
	});
	// VIDEOCASE
	gltfLoader.load("static/vhsblue.glb", function(vid) {
		vid.scene.position.y = 0.25;
		vid.scene.position.x = -1;
		scene.add(vid.scene);
		tape.add(vid.scene);
	});
	// SHOWREEL
	gltfLoader.load("static/showreel2.glb", function(showreel) {
		showreel.scene.traverse(function(child) {
			if (child.isMesh) {
				// child.castShadow = true;
				// child.receiveShadow = true;
				// child.geometry.computeVertexNormals()
			}
		});
		scene.add(showreel.scene);
		global.add(showreel.scene);
		raycasterObjects.push(showreel.scene);
	});
	gltfLoader.load("static/reel3.glb", function(reel1) {
		firstReel = reel1.scene;
		scene.add(reel1.scene);
		global.add(reel1.scene);
		// reelsMove.add(reel1.scene)
		reel1.scene.position.x = 0.28;
		reel1.scene.position.y = 1.2;
		reel1.scene.position.z = -7.38;
	});
	gltfLoader.load("static/reel4.glb", function(reel2) {
		secondReel = reel2.scene;
		scene.add(reel2.scene);
		global.add(reel2.scene);
		// reelsMove.add(reel1.scene)
		reel2.scene.position.x = 1.03;
		reel2.scene.position.y = 1.2;
		reel2.scene.position.z = -7.38;
	});
	// MECHANICAL ARM
	let mixer = null;
	gltfLoader.load("static/mechanicalArm6.glb", function(mechanical) {
		mechanical.scene.traverse(function(child) {
			if (child.isMesh) {
				child.castShadow = true;
				child.receiveShadow = true;
				// child.geometry.computeVertexNormals()
			}
		});
		mixer = new THREE.AnimationMixer(mechanical.scene);
		const clips = mechanical.animations;
		// const clip = THREE.AnimationClip.findByName(clips, 'Action')
		// const action = mixer.clipAction(clip);
		// action.play()
		clips.forEach(function(clip) {
			const action = mixer.clipAction(clip);
			action.play();
		});
		setTimeout(function(clips) {
			//your code to be executed after 1 second
		}, 5000);
		scene.add(mechanical.scene);
		global.add(mechanical.scene);
	});
	// ABOUT
	gltfLoader.load("static/about.glb", function(about) {
		about.scene.traverse(function(child) {
			if (child.isMesh) {
				child.castShadow = true;
				child.receiveShadow = true;
				// child.geometry.computeVertexNormals()
			}
		});
		scene.add(about.scene);
		global.add(about.scene);
		raycasterObjects.push(about.scene);
	});
	// PORTRAITS
	const portraitsTexture = textureLoader.load("static/PortraitsLo.jpg");
	portraitsTexture.flipY = false;
	const portraitsMaterial = new THREE.MeshStandardMaterial({
		map: portraitsTexture,
	});
	gltfLoader.load("static/portraitsNoMat.glb", function(potraits) {
		potraits.scene.traverse(function(child) {
			if (child.isMesh) {
				// child.castShadow = true;
				// child.receiveShadow = true;
				// child.geometry.computeVertexNormals()
				child.material = portraitsMaterial;
			}
		});
		scene.add(potraits.scene);
		global.add(potraits.scene);
		raycasterObjects.push(potraits.scene);
	});
	// COMPLETED
	gltfLoader.load("static/finalcompleted.glb", function(completed) {
		completed.scene.traverse(function(child) {
			if (child.isMesh) {
				child.castShadow = true;
				// child.receiveShadow = true;
				// child.geometry.computeVertexNormals()
			}
		});
		scene.add(completed.scene);
		global.add(completed.scene);
		raycasterObjects.push(completed.scene);
	});
	// AWARDS
	gltfLoader.load("static/awardsnew.glb", function(awards, awards2) {
		awards.scene.traverse(function(child) {
			if (child.isMesh) {
				child.castShadow = true;
				child.receiveShadow = true;
				// child.geometry.computeVertexNormals()
			}
		});
		scene.add(awards.scene);
		global.add(awards.scene);
		raycasterObjects.push(awards.scene);
	});
	const tankMaterial = new THREE.MeshBasicMaterial({
		color: "#a8add7",
		transparent: true,
		opacity: 0.5,
	});
	gltfLoader.load("static/tank2.glb", function(tank) {
		tank.scene.traverse(function(child) {
			if (child.isMesh) {
				// tank.castShadow = true;
				// tank.receiveShadow = true;
				// child.geometry.computeVertexNormals()
				child.material = tankMaterial;
			}
		});
		scene.add(tank.scene);
		global.add(tank.scene);
	});
	const brainMove = new THREE.Group();
	scene.add(brainMove);
	gltfLoader.load("static/brainNew.glb", function(brain) {
		brain.scene.traverse(function(child) {
			if (child.isMesh) {
				child.castShadow = true;
				// tank.receiveShadow = true;
				// child.geometry.computeVertexNormals()
				// child.material = tankMaterial
			}
			// walls.scene.castShadow = true;
			brain.scene.position.y = 0.15;
			brain.scene.position.x = -1.03;
		});
		scene.add(brain.scene);
		global.add(brain.scene);
		brainMove.add(brain.scene);
	});
	const bubbleGeometry = new THREE.SphereGeometry(0.04, 16, 16);
	const bubbleMaterial = new THREE.MeshBasicMaterial({
		color: "#708395"
	});
	const bubble = new THREE.Mesh(bubbleGeometry, bubbleMaterial);
	const bubble2 = new THREE.Mesh(bubbleGeometry, bubbleMaterial);
	const bubble3 = new THREE.Mesh(bubbleGeometry, bubbleMaterial);
	const bubble4 = new THREE.Mesh(bubbleGeometry, bubbleMaterial);
	const bubble5 = new THREE.Mesh(bubbleGeometry, bubbleMaterial);
	const bubble6 = new THREE.Mesh(bubbleGeometry, bubbleMaterial);
	scene.add(bubble, bubble2, bubble3, bubble4, bubble5, bubble6);
	bubble.position.set(1, 1.2, -1.6);
	bubble2.position.set(0.45, 1.2, -1.2);
	bubble2.scale.set(2, 2, 2);
	bubble3.position.set(0, 1.2, -1.8);
	bubble4.position.set(0.7, 1.2, -2);
	bubble5.position.set(0.2, 1.3, -1.5);
	bubble6.position.set(0.6, 1.3, -1.65);
	// WINE
	global.add(bubble, bubble2, bubble3, bubble4, bubble5, bubble6);
	gltfLoader.load("static/wineitself.glb", function(wine) {
		wine.scene.traverse(function(child) {
			if (child.isMesh) {
				//
			}
		});
		scene.add(wine.scene);
		global.add(wine.scene);
	});
	gltfLoader.load("static/wineglasses.glb", function(glasses) {
		glasses.scene.traverse(function(child) {
			if (child.isMesh) {
				child.castShadow = true;
				child.receiveShadow = true;
				child.material = tankMaterial;
			}
		});
		scene.add(glasses.scene);
		global.add(glasses.scene);
	});
	const bottleMaterial = new THREE.MeshBasicMaterial({
		color: "#403f0f",
		transparent: true,
		opacity: 0.95,
	});
	gltfLoader.load("static/winebottle.glb", function(bottle) {
		bottle.scene.traverse(function(child) {
			if (child.isMesh) {
				child.castShadow = true;
				child.receiveShadow = true;
				child.material = bottleMaterial;
			}
		});
		scene.add(bottle.scene);
		global.add(bottle.scene);
	});
	gltfLoader.load("static/cork.glb", function(cork) {
		cork.scene.traverse(function(child) {
			if (child.isMesh) {
				// child.material = bottleMaterial
			}
		});
		scene.add(cork.scene);
		global.add(cork.scene);
	});
	global.position.y = parameters.modelmoveY;
	global.position.x = parameters.modelmoveX;
	/**
	 * Sizes
	 */
	const sizes = {
		width: window.innerWidth,
		height: window.innerHeight,
	};
	/**
	 * Camera
	 */
	// Base camera
	function mediaQuery(z) {}
	var z = window.matchMedia("(max-width: 800px)")
	mediaQuery(z)
	z.addListener(mediaQuery)

	var aspect = window.innerWidth / window.innerHeight;
	var size;
	if (z.matches) {
		 size = 25;
		} else {
			size = 15.5;
		}
	var camera = new THREE.OrthographicCamera(-size * aspect, size * aspect, size, -size, 1, 100);
	scene.add(camera);
	camera.position.set(-10, 10, 10);
	const persp = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100);
	persp.position.x = -25; //newPosition.x
	persp.position.y = 20; //newPosition.y
	persp.position.z = 25; //newPosition.z
	scene.add(persp);
	let activeCamera = camera;
	window.addEventListener("resize", () => {
		// Update sizes
		sizes.width = window.innerWidth;
		sizes.height = window.innerHeight;
		// Update camera
		const newAspect = sizes.width / sizes.height;
		camera.left = -size * newAspect;
		camera.right = size * newAspect;
		camera.updateProjectionMatrix();
		// Update camera
		persp.aspect = sizes.width / sizes.height;
		persp.updateProjectionMatrix();
		// Update renderer
		renderer.setSize(sizes.width, sizes.height);
		renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
		renderer2.setSize(sizes.width, sizes.height);
		// renderer2.setPixelRatio(Math.min(window.devicePixelRatio, 2));
	});
	tape.position.x = -2.5
	tape.position.y = 4.65
	tape.position.z = -7.9
	var controls = new OrbitControls(camera, canvas);
	var perspcontrols = new OrbitControls(persp, canvas);
	const setControls = () => {
		// Controls
		controls.enableDamping = true;
		controls.minPolarAngle = Math.PI / 3.5;
		controls.maxPolarAngle = Math.PI / 3.5;
		controls.maxAzimuthAngle = THREE.Math.degToRad(360);
		controls.minAzimuthAngle = THREE.Math.degToRad(270);
		controls.target = new THREE.Vector3(0, 0, 0);
		// controls.minDistance = 11;
		// controls.maxDistance = 12;
		perspcontrols.enableDamping = true;
		perspcontrols.minPolarAngle = Math.PI / 3.5;
		perspcontrols.maxPolarAngle = Math.PI / 3.5;
		perspcontrols.maxAzimuthAngle = THREE.Math.degToRad(360);
		perspcontrols.minAzimuthAngle = THREE.Math.degToRad(270);
		perspcontrols.target = new THREE.Vector3(0, 0, 0);
		// gui.add(controls.target , 'x', -500, 500 ).step(0.01)
		// gui.add(controls.target , 'y', -500, 500 ).step(0.01)
		// gui.add(controls.target , 'z', -500, 500 ).step(0.01)
	};
	setControls();
	window.resetModel = () => {
		// More things need to be reset to their original values here!
		// console.log("RESET");
		activeCamera = persp;
		camera.position.set(-10, 10, 10);
		new TWEEN.Tween(persp.position).to({
			x: -25,
			y: 20,
			z: 25,
		}, 1250).onComplete(() => {
			resetSceneLighting();
			setControls();
			activeCamera = camera;
		}).easing(TWEEN.Easing.Cubic.In).start();
	};
	// CURSOR

	document.addEventListener("mousemove", (e) => {
		cursor.setAttribute("style", "top: " + (e.pageY - 40) + "px; left: " + (e.pageX - 103) + "px;");
	});
	document.addEventListener("click", () => {
		cursor.classList.add("model-expand");
		setTimeout(() => {
			cursor.classList.remove("model-expand");
		}, 500);
	});
	// MOUSE
	const mouse = new THREE.Vector2();
	window.addEventListener("mousemove", (event) => {
		mouse.x = (event.clientX / sizes.width) * 2 - 1;
		mouse.y = -(event.clientY / sizes.height) * 2 + 1;
	});

	function mediaQuery(x) {}
	var x = window.matchMedia("(max-width: 800px)")
	mediaQuery(x)
	x.addListener(mediaQuery)

	function resetSceneLighting() {
		theWalls.children[0].material.opacity = 1;
		perspcontrols.target.x = 0;
		perspcontrols.target.y = 0;
		perspcontrols.target.z = 0;
		renderer.setClearColor("#ffffff");
		directionalLight.castShadow = true;
		directionalLight.shadow.mapSize.width = 2048;
		directionalLight.shadow.mapSize.height = 2048;
		directionalLight.shadow.camera.top = 15;
		directionalLight.shadow.camera.right = 15;
		directionalLight.shadow.camera.bottom = -15;
		directionalLight.shadow.camera.left = -15;
		directionalLight.shadow.bias = -0.0001;
		directionalLight.position.set(0.658, 8.648, 5.153);
		directionalLight.intensity = 0.695;
		ambientLight.intensity = 1.548;
		candleLight.intensity = 0;
		iframeDiv.innerHTML = '<div></div>';
		iframeDiv.classList.add("this-iframe");
		container2.style.opacity = "0";
		perspcontrols.minPolarAngle = Math.PI / 3.5;
		perspcontrols.maxPolarAngle = Math.PI / 3.5;
		perspcontrols.maxAzimuthAngle = THREE.Math.degToRad(360);
		perspcontrols.minAzimuthAngle = THREE.Math.degToRad(270);
		renderer.setSize(sizes.width, sizes.height);
		renderer2.setSize(sizes.width, sizes.height);
	}

	function moveToShowreel() {
		const requiredAssets = [
			renderer,
			theWalls,
			activeCamera,
			perspcontrols,
			candleLight,
			directionalLight,
			ambientLight,
			iframeDiv,
		];
		if (requiredAssets.some((element) => !element)) {
			console.log("Retry occurring");
			return setTimeout(() => moveToShowreel(), 250);
		}
		resetSceneLighting();
		mediaQuery(z)

		iframeDiv.innerHTML = '<iframe id="showreel-itself" src="https://player.vimeo.com/video/714393918?h=5c242cedd5&&title=0&byline=0&portrait=0;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;""></iframe>';
		activeCamera = persp;
		perspcontrols.target.y = 9;
		perspcontrols.minPolarAngle = Math.PI / 2;
		perspcontrols.maxPolarAngle = Math.PI / 2;
		history.pushState("data to be passed", "Showreel", "/showreel");
		
		
			// HANDLE MENU
			$(".nav-menu-in-development").click(function(event) {
				document.getElementById("showreel-button-holder").classList.add("dead")
				var thisLink = $(".nav-menu-in-development")
					  URL = thisLink.href = '/in-development';
					  $('<a href="' + URL + '">External Link</a>')[0].click();
			  });
			  
			  $(".nav-menu-in-production").click(function(event) {
				document.getElementById("showreel-button-holder").classList.add("dead")
				var thisLink = $(".nav-menu-in-production")
					  URL = thisLink.href = '/in-production';
					  $('<a href="' + URL + '">External Link</a>')[0].click();
			  });
			  
			  $(".nav-menu-completed").click(function(event) {
				document.getElementById("showreel-button-holder").classList.add("dead")
				var thisLink = $(".nav-menu-completed")
				URL = thisLink.href = '/completed';
				$('<a href="' + URL + '">External Link</a>')[0].click();
			  });
			  
			  $(".nav-menu-contact").click(function(event) {
				document.getElementById("showreel-button-holder").classList.add("dead")
				var thisLink = $(".nav-menu-contact")
				URL = thisLink.href = '/contact';
				$('<a href="' + URL + '">External Link</a>')[0].click();
			  });
			  
			  $(".nav-menu-staff").click(function(event) {
				document.getElementById("showreel-button-holder").classList.add("dead")
				var thisLink = $(".nav-menu-staff")
				URL = thisLink.href = '/us';
				$('<a href="' + URL + '">External Link</a>')[0].click();
			  });	  
	
		
		if (z.matches) {
			new TWEEN.Tween(perspcontrols.target).to({
				x: -0.365,
				y: 2.25,
				z: -4.5,
			}, 2000).onComplete(() => {
				container2.style.opacity = "1";
				new TWEEN.Tween(perspcontrols.target).to({
					x: -0.364,
					y: 1.75,
					z: -4.5,
				}, 1000).easing(TWEEN.Easing.Cubic.Out).start();
			}).easing(TWEEN.Easing.Cubic.Out).start();
		} else {
				new TWEEN.Tween(perspcontrols.target).to({
					x: -0.365,
					y: 2.25,
					z: -5.75,
				}, 1500).onComplete(() => {
					container2.style.opacity = "1";
					new TWEEN.Tween(perspcontrols.target).to({
						x: -0.365,
						y: 1.75,
						z: -5.75,
					}, 1500).easing(TWEEN.Easing.Cubic.Out).start();
				}).easing(TWEEN.Easing.Cubic.Out).start();
			}

		
		new TWEEN.Tween(persp.position).to({
			x: -0.375,
			y: 1,
			z: -4.15,
		}, 3000).onComplete(() => {
			perspcontrols.maxAzimuthAngle = THREE.Math.degToRad(360);
			perspcontrols.minAzimuthAngle = THREE.Math.degToRad(360);
			document.getElementById("non-webgl-page").style.pointerEvents = "none"
			document.getElementById("showreel-button-holder").style.pointerEvents = "auto"
			iframeDiv.classList.remove("this-iframe");
			iframeDiv.classList.add("iframe-interactive");
			$('.showreel-buttons').removeClass( "down" )
			$('.renderer').attr('id', 'showreel-renderer');
			$('.iframe-interactive').attr('id', 'showreel-holder');

			$("#showreel-play-btn").click(function () {
				let showreelPlay = document.getElementById("showreel-itself");     
				let showreelPlayBtn = document.getElementById("showreel-play-btn");     
				let showreelPauseBtn = document.getElementById("showreel-pause-btn"); 
				showreelPauseBtn.classList.toggle('mobile-dead')
				showreelPlayBtn.classList.toggle('mobile-dead')
				var viddata = { method: "play" };
					  showreelPlay.contentWindow.postMessage(JSON.stringify(viddata), "*");
			  });
			  $("#showreel-pause-btn").click(function () {
				let showreelPlayBtn = document.getElementById("showreel-play-btn");     
				let showreelPauseBtn = document.getElementById("showreel-pause-btn"); 
				showreelPlayBtn.classList.toggle('mobile-dead')
				showreelPauseBtn.classList.toggle('mobile-dead')
				let showreelPlay = document.getElementById("showreel-itself");
					  var viddata = { method: "pause" };
					  showreelPlay.contentWindow.postMessage(JSON.stringify(viddata), "*");
			  });		
		
			  $("#showreel-fullscreen-btn").click(function () {
				let showreelPlay = document.getElementById("showreel-itself");
				let showreelHolder = document.getElementById("showreel-holder");
				let showreelRenderer = document.getElementById("showreel-renderer");
				let showreelRenderChild = showreelRenderer.children[0]
				showreelRenderChild.classList.toggle('full-screen')
				showreelHolder.classList.toggle('full-screen')
				showreelPlay.classList.toggle('full-screen')
			  });
		
			  $("#showreel-back-btn").click(function () {
				let showreelPlay = document.getElementById("showreel-itself");
				let showreelHolder = document.getElementById("showreel-holder");
				let showreelRenderer = document.getElementById("showreel-renderer");
				let showreelBtnHolder = document.getElementById("showreel-button-holder");
				let showreelRenderChild = showreelRenderer.children[0]
				showreelRenderChild.classList.remove('full-screen')
				showreelHolder.classList.remove('full-screen')
				showreelPlay.classList.remove('full-screen')
				showreelBtnHolder.classList.add('down')
				showreelBtnHolder.classList.add('dead')
				route(event,'/')
			  });
		}).easing(TWEEN.Easing.Cubic.Out).start();
	}

	function moveToAbout() {
		const requiredAssets = [
			renderer,
			theWalls,
			activeCamera,
			perspcontrols,
			candleLight,
			directionalLight,
			ambientLight,
		];
		if (requiredAssets.some((element) => !element)) {
			console.log("Retry occurring");
			return setTimeout(() => moveToAbout(), 250);
		}
		resetSceneLighting();
		renderer.setClearColor("#320909");
		theWalls.children[0].material.opacity = 0;
		activeCamera = persp;
		candleLight.color.set(0xffaa33);
		candleLight.position.x = -1.25;
		candleLight.position.y = 3.25;
		candleLight.position.z = 5.5;
		history.pushState("data to be passed", "About", "/about");
		gsap.delayedCall(1.5, () => {
			document.getElementById("about-speaker-holder").classList.remove("go-left")
	})
		
		gsap.delayedCall(2, () => {
			document.getElementById("about-dialogue-holder").classList.remove("down")
	})
		
			// HANDLE MENU
			$(".nav-menu-in-development").click(function(event) {
				document.getElementById("about-wrapper").classList.add("dead")
				var thisLink = $(".nav-menu-in-development")
					  URL = thisLink.href = '/in-development';
					  $('<a href="' + URL + '">External Link</a>')[0].click();
			  });
			  
			  $(".nav-menu-in-production").click(function(event) {
				document.getElementById("about-wrapper").classList.add("dead")
				var thisLink = $(".nav-menu-in-production")
					  URL = thisLink.href = '/in-production';
					  $('<a href="' + URL + '">External Link</a>')[0].click();
			  });
			  
			  $(".nav-menu-completed").click(function(event) {
				document.getElementById("about-wrapper").classList.add("dead")
				var thisLink = $(".nav-menu-completed")
				URL = thisLink.href = '/completed';
				$('<a href="' + URL + '">External Link</a>')[0].click();
			  });
			  
			  $(".nav-menu-contact").click(function(event) {
				document.getElementById("about-wrapper").classList.add("dead")
				var thisLink = $(".nav-menu-contact")
				URL = thisLink.href = '/contact';
				$('<a href="' + URL + '">External Link</a>')[0].click();
			  });
			  
			  $(".nav-menu-staff").click(function(event) {
				document.getElementById("about-wrapper").classList.add("dead")
				var thisLink = $(".nav-menu-staff")
				URL = thisLink.href = '/us';
				$('<a href="' + URL + '">External Link</a>')[0].click();
			  });	  
	
		
		new TWEEN.Tween(perspcontrols.target).to({
			x: -1.1,
			y: 2,
			z: 7,
		}, 3000).easing(TWEEN.Easing.Cubic.Out).start();
		new TWEEN.Tween(persp.position).to({
			x: -0.2,
			y: -3,
			z: 11,
		}, 3000).easing(TWEEN.Easing.Cubic.Out).start();
		new TWEEN.Tween(ambientLight).to({
			intensity: 0,
		}, 3000).easing(TWEEN.Easing.Cubic.Out).start();
		new TWEEN.Tween(directionalLight).to({
			intensity: 0,
		}, 3000).onComplete(() => {
			perspcontrols.maxAzimuthAngle = THREE.Math.degToRad(360);
			perspcontrols.minAzimuthAngle = THREE.Math.degToRad(360);
		}).easing(TWEEN.Easing.Cubic.Out).start();
		new TWEEN.Tween(candleLight).to({
			intensity: 2,
		}, 3000).easing(TWEEN.Easing.Cubic.Out).start();
	}

	function moveToJobs() {
		const requiredAssets = [
			renderer,
			renderer2,
			theWalls,
			activeCamera,
			perspcontrols,
			candleLight,
			directionalLight,
			ambientLight,
		];
		if (requiredAssets.some((element) => !element)) {
			console.log("Retry occurring");
			return setTimeout(() => moveToJobs(), 100);
		}
		resetSceneLighting();
		renderer.setClearColor("#0f1419");
		theWalls.children[0].material.opacity = 0;
		// jobsChair.children[1].children[0].position.x = -13
		activeCamera = persp;
		perspcontrols.target.y = 8.5;
		candleLight.position.x = -6.5;
		candleLight.position.y = 2.75;
		candleLight.position.z = 2.8;
		candleLight.decay = 6;
		candleLight.color.set(0xffffff);
		perspcontrols.minPolarAngle = Math.PI / 2;
		perspcontrols.maxPolarAngle = Math.PI / 2;
		history.pushState("data to be passed", "Jobs", "/jobs");
		
		// HANDLE MENU
		$(".nav-menu-in-development").click(function(event) {
			document.getElementById("jobs-wrapper").classList.add("dead")
			var thisLink = $(".nav-menu-in-development")
				  URL = thisLink.href = '/in-development';
				  $('<a href="' + URL + '">External Link</a>')[0].click();
		  });
		  
		  $(".nav-menu-in-production").click(function(event) {
			document.getElementById("jobs-wrapper").classList.add("dead")
			var thisLink = $(".nav-menu-in-production")
				  URL = thisLink.href = '/in-production';
				  $('<a href="' + URL + '">External Link</a>')[0].click();
		  });
		  
		  $(".nav-menu-completed").click(function(event) {
			document.getElementById("jobs-wrapper").classList.add("dead")
			var thisLink = $(".nav-menu-completed")
			URL = thisLink.href = '/completed';
			$('<a href="' + URL + '">External Link</a>')[0].click();
		  });
		  
		  $(".nav-menu-contact").click(function(event) {
			document.getElementById("jobs-wrapper").classList.add("dead")
			var thisLink = $(".nav-menu-contact")
			URL = thisLink.href = '/contact';
			$('<a href="' + URL + '">External Link</a>')[0].click();
		  });
		  
		  $(".nav-menu-staff").click(function(event) {
			document.getElementById("jobs-wrapper").classList.add("dead")
			var thisLink = $(".nav-menu-staff")
			URL = thisLink.href = '/us';
			$('<a href="' + URL + '">External Link</a>')[0].click();
		  });	  


		// TWEEN
		new TWEEN.Tween(perspcontrols.target).to({
			x: -1.7,
			y: 2,
			z: 2.8,
		}, 2250).easing(TWEEN.Easing.Cubic.Out).start();
		new TWEEN.Tween(persp.position).to({
			x: -2,
			y: 10.25,
			z: 2.8,
		}, 2250).easing(TWEEN.Easing.Cubic.Out).start();
		new TWEEN.Tween(ambientLight).to({
			intensity: 0,
		}, 2000).onComplete(() => {
			document.getElementById("jobs-wrapper").classList.remove("dead")
			perspcontrols.maxAzimuthAngle = THREE.Math.degToRad(270);
			perspcontrols.minAzimuthAngle = THREE.Math.degToRad(270);
		}).easing(TWEEN.Easing.Cubic.Out).start();
		new TWEEN.Tween(directionalLight).to({
			intensity: 0,
		}, 2500).onComplete(() => {
			document.getElementById("jobs-wrapper").classList.remove("disappear")
			gsap.delayedCall(0.5, () => {
				document.getElementById("jobs-inner-wrapper").classList.remove("down")
			})
			new TWEEN.Tween(candleLight).to({
				intensity: 1.55,
			}, 2250).easing(TWEEN.Easing.Cubic.Out).start();
		}).easing(TWEEN.Easing.Cubic.Out).start();
	}

// BUTTON EVENTS
// $(".nav-menu-in-development").click(function(event) {
//   moveToIndev(event)
// });

// $(".nav-menu-in-production").click(function(event) {
//   moveToInProd(event)
// });

// $(".nav-menu-completed").click(function(event) {
//   moveToCompleted(event)
// });

// $(".nav-menu-contact").click(function(event) {
//   moveToContact(event)
// });

// $(".nav-menu-staff").click(function(event) {
//   moveToStaffRoom(event)
// });
// $(".awards-move").click(function(event) {
//   moveToAwards(event)
// });

// NEW MOVE TO AWARDS
	function moveToAwards(event) {
		const requiredAssets = [
			renderer,
			renderer2,
			theWalls,
			activeCamera,
			perspcontrols,
			candleLight,
			directionalLight,
			ambientLight,
		];
		if (requiredAssets.some((element) => !element)) {
			console.log("Retry occurring");
			return setTimeout(() => moveToAwards(), 100);
		}
		resetSceneLighting();
	history.pushState("data to be passed", "Awards", "/awards");

	// HANDLE MENU
	$(".blink-home-logo").click(function(event) {
		document.getElementById("awards-wrapper").classList.add("dead")
		var thisLink = $(".blink-home-logo")
			  URL = thisLink.href = '/';
			  $('<a href="' + URL + '">External Link</a>')[0].click();
	  });
	$(".nav-menu-in-development").click(function(event) {
		document.getElementById("awards-wrapper").classList.add("dead")
		var thisLink = $(".nav-menu-in-development")
			  URL = thisLink.href = '/in-development';
			  $('<a href="' + URL + '">External Link</a>')[0].click();
	  });
	  
	  $(".nav-menu-in-production").click(function(event) {
		document.getElementById("awards-wrapper").classList.add("dead")
		var thisLink = $(".nav-menu-in-production")
			  URL = thisLink.href = '/in-production';
			  $('<a href="' + URL + '">External Link</a>')[0].click();
	  });
	  
	  $(".nav-menu-completed").click(function(event) {
		document.getElementById("awards-wrapper").classList.add("dead")
		var thisLink = $(".nav-menu-completed")
		URL = thisLink.href = '/completed';
		$('<a href="' + URL + '">External Link</a>')[0].click();
	  });
	  
	  $(".nav-menu-contact").click(function(event) {
		document.getElementById("awards-wrapper").classList.add("dead")
		var thisLink = $(".nav-menu-contact")
		URL = thisLink.href = '/contact';
		$('<a href="' + URL + '">External Link</a>')[0].click();
	  });
	  
	  $(".nav-menu-staff").click(function(event) {
		document.getElementById("awards-wrapper").classList.add("dead")
		var thisLink = $(".nav-menu-staff")
		URL = thisLink.href = '/us';
		$('<a href="' + URL + '">External Link</a>')[0].click();
	  });	  


			activeCamera = persp;
			perspcontrols.target.y = 8.25;
			perspcontrols.minPolarAngle = Math.PI / 2;
			perspcontrols.maxPolarAngle = Math.PI / 2;
			new TWEEN.Tween(perspcontrols.target).to({
				x: 1,
				y: 10.7,
				z: -0.9725,
			}, 1500).easing(TWEEN.Easing.Cubic.Out).start();
			new TWEEN.Tween(persp.position).to({
				x: -3,
				y: 10.7,
				z: -0.9725,
			}, 2500).onComplete(() => {
				document.getElementById("awards-wrapper").classList.remove("disappear")
				// console.log(document.getElementById("awards-wrapper"))
				new TWEEN.Tween(persp.position).to({
					x: 0,
					y: 10.7,
					z: -0.9725,
				}, 500).onComplete(() => {
					document.getElementById("awards-wrapper").classList.remove("down")
				}).easing(TWEEN.Easing.Cubic.Out).start();
			}).easing(TWEEN.Easing.Cubic.Out).start();
	}


// NEW MOVE TO INDEV
	function moveToIndev(event) {
    mediaQuery(x)
		activeCamera = persp;
		new TWEEN.Tween(perspcontrols.target).to({
			x: -5.5,
			y: 2.2,
			z: -1.8,
		}, 3000).easing(TWEEN.Easing.Cubic.Out).start();
		new TWEEN.Tween(persp.position).to({
			x: -5.5,
			y: 2.2,
			z: -1.75,
		}, 3000).onComplete(() => {
			if (x.matches) {
			// 	//small screen
				var thisLink = $(".nav-menu-in-development")
				URL = thisLink.href = '/in-development';
				$('<a href="' + URL + '">External Link</a>')[0].click();
			} else {
				//big screen
				route(event, "/in-development");
			}
		}).easing(TWEEN.Easing.Cubic.Out).start();
	}

// NEW MOVE TO InProd
	function moveToInProd(event) {
    mediaQuery(x)
    activeCamera = persp;
    new TWEEN.Tween(perspcontrols.target).to({
      x: 2.9,
      y: 2.53,
      z: -0.925,
    }, 3000).onComplete(() => {
			if (x.matches) {
			// 	//small screen
				var thisLink = $(".nav-menu-in-production")
				URL = thisLink.href = '/in-production';
				$('<a href="' + URL + '">External Link</a>')[0].click();
			} else {
				//big screen
				route(event, "/in-production");
			}
		}).easing(TWEEN.Easing.Cubic.Out).start();
    new TWEEN.Tween(persp.position).to({
      x: 2.7,
      y: 2.4,
      z: -0.925,
    }, 3000).easing(TWEEN.Easing.Cubic.Out).start();
	}

// NEW MOVE TO Completed
	function moveToCompleted(event) {
    mediaQuery(x)
    activeCamera = persp;
			new TWEEN.Tween(perspcontrols.target).to({
				x: 4.75,
				y: 1.75,
				z: -8.5,
			}, 3000).easing(TWEEN.Easing.Cubic.Out).start();
			new TWEEN.Tween(persp.position).to({
				x: 4.75,
				y: 1.25,
				z: -7.75,
			}, 3000).onComplete(() => {
				if (x.matches) {
          // 	//small screen
            var thisLink = $(".nav-menu-completed")
            URL = thisLink.href = '/completed';
            $('<a href="' + URL + '">External Link</a>')[0].click();
          } else {
            //big screen
            route(event, "/completed");
          }
			}).easing(TWEEN.Easing.Cubic.Out).start();
	}

// NEW MOVE TO CONTACT
	function moveToContact(event) {
    mediaQuery(x)
    activeCamera = persp;
			new TWEEN.Tween(perspcontrols.target).to({
				x: -7.45,
				y: 2.475,
				z: 8.25,
			}, 3000).easing(TWEEN.Easing.Cubic.Out).start();
			new TWEEN.Tween(persp.position).to({
				x: -7.4,
				y: 2.3,
				z: 8.7,
			}, 3000).onComplete(() => {
        if (x.matches) {
          // 	//small screen
            var thisLink = $(".nav-menu-completed")
            URL = thisLink.href = '/contact';
            $('<a href="' + URL + '">External Link</a>')[0].click();
          } else {
            //big screen
            route(event, "/contact");
          }
			}).easing(TWEEN.Easing.Cubic.Out).start();
	}

// NEW MOVE TO PORTRAITS
	function moveToStaffRoom(event) {
    mediaQuery(x)
    activeCamera = persp;
    // currentintersect.object.material.map = portraitsHQTexture;
    perspcontrols.minPolarAngle = Math.PI / 2;
    perspcontrols.maxPolarAngle = Math.PI / 2;
    perspcontrols.target.y = 7.5;
    new TWEEN.Tween(perspcontrols.target).to({
      x: 9.1,
      y: 3.55,
      z: 8.15,
    }, 3000).easing(TWEEN.Easing.Cubic.Out).start();
    new TWEEN.Tween(persp.position).to({
      x: 4,
      y: 3.55,
      z: 8.15,
    }, 3000).onComplete(() => {
      if (x.matches) {
        // 	//small screen
          var thisLink = $(".nav-menu-staff")
          URL = thisLink.href = '/us';
          $('<a href="' + URL + '">External Link</a>')[0].click();
        } else {
          //big screen
          route(event, "/us");
        }
    }).easing(TWEEN.Easing.Cubic.Out).start();
	}

	function raycasterFunctions(event) {
		// PERSPECTIVE MOVE TO COMPLETED
		if (currentintersect.object === null) {
			return console.log("currentintersect.object is null");
		}
		if (currentintersect.object.parent.name === "Completed") {
		moveToCompleted(event)
		}
		// PERSPECTIVE MOVE TO INDEV
		else if (currentintersect.object.parent.name === "inDevMachine") {
			moveToIndev(event)
		}
		// PERSPECTIVE MOVE TO INPROD
		else if (currentintersect.object.parent.name === "Conveyor_screen_1" || currentintersect.object.parent.name === "InProdMachine" || currentintersect.object.parent.name === "boardslo001" || currentintersect.object.parent.name === "Sphere001") {
      moveToInProd(event)
		}
		// PERSPECTIVE MOVE TO CONTACT
		else if (currentintersect.object.parent.name === "Cube116") {
      moveToContact(event)
		}
		// PERSPECTIVE MOVE TO JOBS
		else if (currentintersect.object.parent.name === "Table005") {
			document.getElementById("jobs-move").click();
		}
		// PERSPECTIVE MOVE TO ABOUT
		else if (currentintersect.object.parent.name === "Cube042") {
			// moveToAbout();
			document.getElementById("about-move").click();
		}
		// PERSPECTIVE MOVE TO SHOWREEL
		if (currentintersect.object.parent.name === "Cube002") {
			document.getElementById("showreel-move").click();
		}
		// PERSPECTIVE MOVE TO AWARDS
		if (currentintersect.object.parent.name === "Cube043") {
			document.getElementById("awards-move").click();
		}
		// PERSPECTIVE MOVE TO PORTRAITS
		if (currentintersect.object.parent.name === "Scene") {
      moveToStaffRoom(event)
		}
	}
	canvas.addEventListener("click", (event) => {
		raycasterFunctions(event);
    menuItself.classList.remove("open")
	});
	// LIGHT
	// NORMAL
	// const ambientLight = new THREE.AmbientLight(0xffffff, 0.25)
	// MORE CARTOONY
	const ambientLight = new THREE.AmbientLight(parameters.ambientLightColor, 1.548);
	// gui
	//   .add(ambientLight, "intensity")
	//   .min(0)
	//   .max(3)
	//   .step(0.001)
	//   .name("ambient intensity");
	scene.add(ambientLight);
	//Directional light
	// NORMAL
	// const directionalLight = new THREE.DirectionalLight(0xffffff, 0.7)
	// MORE CARTOONY
	const directionalLight = new THREE.DirectionalLight(parameters.directionalLightColor, 0.695);
	directionalLight.castShadow = true;
	directionalLight.shadow.mapSize.width = 2048;
	directionalLight.shadow.mapSize.height = 2048;
	directionalLight.shadow.camera.top = 15;
	directionalLight.shadow.camera.right = 15;
	directionalLight.shadow.camera.bottom = -15;
	directionalLight.shadow.camera.left = -15;
	// directionalLight.shadow.camera.near = 25;
	// directionalLight.shadow.camera.far = 250;
	directionalLight.shadow.bias = -0.0001;
	directionalLight.position.set(1.658, 8.648, 5.153);
	scene.add(directionalLight);
	const candleLight = new THREE.PointLight(0xffaa33, 0, 10, 2);
	candleLight.position.set(-0.25, 1.5, 5.5);
	scene.add(candleLight);
	/**
	 * Renderer
	 */
	const renderer = new THREE.WebGLRenderer({
		canvas: canvas,
		antialias: true,
	});
	renderer.shadowMap.enabled = true;
	renderer.shadowMap.type = THREE.PCFSoftShadowMap;
	renderer.setSize(sizes.width, sizes.height);
	renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
	renderer.setClearColor("#ffffff");
	renderer.outputEncoding = THREE.sRGBEncoding;
	renderer.toneMapping = THREE.LinearToneMapping;
	const raycaster = new THREE.Raycaster();
	let currentintersect = null;
	// CSS3D
	var Element = function(id, x, y, z, ry) {
		iframeDiv = document.createElement("div");
		iframeDiv.style.width = "960px";
		iframeDiv.style.height = "540px";
		iframeDiv.style.backgroundColor = "#000";
		iframeDiv.innerHTML = "<div></div>";
		iframeDiv.classList.add("this-iframe");
		iframe = iframeDiv.firstChild;
		var object = new CSS3DObject(iframeDiv);
		iframe.style.pointerEvents = "none";
		object.position.set(x, y, z);
		// object.rotation.y = ry;
		return object;
	};
	var container2 = document.getElementById("model-container");
	const renderer2 = new CSS3DRenderer();
	renderer2.setSize(sizes.width, sizes.height);
	renderer2.domElement.style.position = "absolute";
	renderer2.domElement.style.top = 0;
	renderer2.domElement.classList.add('renderer')
	// renderer.setSize( window.innerWidth, window.innerHeight );
	container2.appendChild(renderer2.domElement);
	const vimeoscreen = new THREE.Group();
	vimeoscreen.position.set(-0.35, 2.165, -7.525);
	vimeoscreen.scale.set(0.001478, 0.001478, 0.001478);
	vimeoscreen.add(new Element("297686405", 0, 0, 0, 0));
	scene.add(vimeoscreen);
	/**
	 * Animate
	 */
	const tl3 = gsap.timeline({
		repeat: -1,
		delay: 10.5,
		repeatDelay: 1.5,
		defaults: {
			ease: Sine.easeOut
		},
	});
	tl3.to(tape.position, {
		duration: 0.3,
		y: 2.25
	});
	const clock = new THREE.Clock();
	const tick = () => {
		TWEEN.update();
		var delta = clock.getDelta();
		const elapsedTime = clock.getElapsedTime();
		// Animate Objects
		if (mixer) mixer.update(delta);
		var bubbleanim = Math.sin(elapsedTime * 0.7) * 0.6 + 1.55;
		bubble.position.y = bubbleanim;
		bubble.scale.y = bubbleanim;
		bubble.scale.x = bubbleanim;
		bubble.scale.z = bubbleanim;
		var bubble2anim = Math.sin(elapsedTime * 0.6) * 0.55 + 1.6;
		bubble2.position.y = bubble2anim;
		bubble2.scale.y = bubble2anim;
		bubble2.scale.x = bubble2anim;
		bubble2.scale.z = bubble2anim;
		var bubble3anim = Math.sin(elapsedTime * 0.5) * 0.55 + 1.65;
		bubble3.position.y = bubble3anim;
		bubble3.scale.y = bubble3anim;
		bubble3.scale.x = bubble3anim;
		bubble3.scale.z = bubble3anim;
		var bubble4anim = Math.sin(elapsedTime * 0.75) * 0.75 + 1.575;
		bubble4.position.y = bubble4anim;
		bubble4.scale.y = bubble4anim;
		bubble4.scale.x = bubble4anim;
		bubble4.scale.z = bubble4anim;
		var bubble5anim = Math.sin(elapsedTime * 0.65) * 0.45 + 1.6;
		bubble5.position.y = bubble5anim;
		bubble5.scale.y = bubble5anim;
		bubble5.scale.x = bubble5anim;
		bubble5.scale.z = bubble5anim;
		var bubble6anim = Math.sin(elapsedTime * 0.6) * 0.5 + 1.6;
		bubble6.position.y = bubble6anim;
		bubble6.scale.y = bubble6anim;
		bubble6.scale.x = bubble6anim;
		bubble6.scale.z = bubble6anim;
		brainMove.position.y = Math.sin(elapsedTime * 1.2) * 0.135;
		if (firstReel) firstReel.rotation.z -= 0.01;
		if (secondReel) secondReel.rotation.z -= 0.01;
		// Cast Ray
		raycaster.setFromCamera(mouse, camera);
		const intersects = raycaster.intersectObjects(raycasterObjects);
		if (intersects.length && activeCamera != persp && menuItself.classList.value !== "nav-menu mobile open") {
			if (currentintersect === null) {
				cursor.style.opacity = "1";
			}
			document.body.style.cursor = "pointer";
			currentintersect = intersects[0];
			if (currentintersect.object.parent.name === "Cube116") {
				cursor.innerHTML = "Contact";
				// console.log(currentintersect.object.parent.children.material)
				// currentintersect.object.parent.children.material.color.set('#ffffff')
			} else if (currentintersect.object.parent.name === "Table005" || currentintersect.object.parent.name === "pCylinder21") {
				cursor.innerHTML = "Jobs";
			} else if (currentintersect.object.parent.name === "inDevMachine") {
				cursor.innerHTML = "In Development";
			} else if (currentintersect.object.parent.name === "Cube002") {
				cursor.innerHTML = "Showreel";
			} else if (currentintersect.object.parent.name === "Completed") {
				cursor.innerHTML = "Completed";
			} else if (currentintersect.object.parent.name === "Scene") {
				cursor.innerHTML = "Staff Room";
			} else if (currentintersect.object.parent.name === "Conveyor_screen_1" || currentintersect.object.parent.name === "InProdMachine" || currentintersect.object.parent.name === "boardslo001" || currentintersect.object.parent.name === "Sphere001") {
				cursor.innerHTML = "In Production";
			} else if (currentintersect.object.parent.name === "Cube043") {
				cursor.innerHTML = "Awards";
			} else if (currentintersect.object.parent.name === "Cube042") {
				cursor.innerHTML = "About Us";
			}
		} 
    else {
			if (currentintersect) {
				// console.log(currentintersect.object.parent.name);
				document.body.style.cursor = "default";
			}
			cursor.style.opacity = "0";
			currentintersect = null;
		} 
		// Update controls
		controls.update();
		perspcontrols.update();
		// Render
		renderer.render(scene, activeCamera);
		renderer2.render(scene, activeCamera);
		// Call tick again on the next frame
		window.requestAnimationFrame(tick);
	};
	tick();
	window.modelHasRendered = true;
	if (navigationInstruction) {
		// console.log(navigationInstruction);
		if (navigationInstruction === "/jobs") {
			moveToJobs();
		}
		if (navigationInstruction === "/about") {
			moveToAbout();
		}
		if (navigationInstruction === "/showreel") {
			moveToShowreel();
		}
		if (navigationInstruction === "/awards") {
			moveToAwards();
		}
	}
};
window.renderModel = renderModel;